/* -------- general start -------- */

.btn-primary {
  background-color: #039589;
  border-color: #039589;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #039589 !important;
  border-color: #039589 !important;
  box-shadow: none;
}

.form-control {
  border: 3px solid #f3f3f3;
  border-radius: 10px !important;
}

/* -------- general end -------- */

/* -------- company-auth start -------- */

.auth-layout .content-wrapper {
  background-color: #191919;
}

.auth-layout .page-body-wrapper {
  margin-left: 0;
}

.auth-layout .content-wrapper .auth-form-light {
  overflow: hidden;
}

/* -------- company-auth end -------- */

/* -------- header start -------- */

.company-verified-tick {
  margin-left: -3px;
  margin-top: -10px;
  font-size: 14px;
}

@media (max-width: 480px) {
  .company-verified-tick {
    margin-left: 64px;
  }
}

/* -------- header end -------- */

/* -------- user end -------- */

.user-verified-tick {
  margin-left: 7px;
  color: #00ff2a;
}

.email-unverified-tick {
  margin-left: 7px;
  color: #ff3838;
}

/* -------- user end -------- */

/* -------- job location start -------- */

.job-location .search input {
  width: 100%;
  padding: 0.5rem 0.81rem;
  border-radius: 10px;
  border: 3px solid #f3f3f3;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
}

.job-location .search input:focus {
  color: #495057;
}

.job-location .search input::-webkit-input-placeholder {
  color: #c9c8c8 !important;
}

/* -------- job location end -------- */

/* -------- contract start -------- */

.css-yk16xz-control {
  border: 3px solid #f3f3f3 !important;
  border-radius: 10px !important;
  background: none !important;
}

.css-1pahdxg-control {
  border: 3px solid #f3f3f3 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.css-yk16xz-control:focus-within, .css-1pahdxg-control:focus-within {
  border-color: #80bdff !important;
}

.css-1wa3eu0-placeholder {
  color: #495057 !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}

.fileContainer .uploadPicturesWrapper {
  width: 1200px !important;
  max-width: 1000px;
}

.fileContainer .uploadPicturesWrapper .uploadPictureContainer {
  margin-top: -160px !important;
}

.header-logo {
  max-width: 200px;
}

.align-center {
  align-items: center !important;
}

.align-bottom {
  align-items: flex-end !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-top {
  align-items: flex-start !important;
}

.padding-std {
  padding-left: 16px;
}

.flex {
  display: flex;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.column-reverse {
  flex-direction: column-reverse !important;
}

.pos-abs {
  position: absolute !important;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.f-f-r {
  flex-flow: row !important;
}

.f-f-c {
  flex-flow: column !important;
}
.b-m-10{
  bottom: -10px !important;
}
.r-15{
  right: 15px !important;
}
.float-right {
  float: right !important;

}

.float-end{
  float: end;
}
.b-0{
  bottom: 0 !important;
}
.table-responsive {
  overflow: hidden !important;
}

.rounded-corners {
  border-radius: 2em !important;
}

.rounded-table {
  border-collapse: separate;
}

.rounded-table td {
  border: solid 1px #000;
}

.rounded-table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

.rounded-table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

.rounded-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.rounded-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.swal-wide {
  width: 85% !important;
  pointer-events: none;
  text-align: left !important;
}
.no-pointer{
  pointer-events: none !important;
}

.swal-wide-click {
  width: 85% !important;
  text-align: left !important;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
}

.full-img {
  max-width: 100%;
  max-height: 100%;
}

.swal-pdf {
  width: 840px !important;
  height: 1188px !important;
}

.avatar-img {
  object-fit: cover !important;
}

.img-scale-down {
  object-fit: scale-down !important;
}

.r-2 {
  right: 2px !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
.t-2 {
  top: 2px !important;
}
.t-50{
  top: 50% !important;
}
.text-align-left {
  text-align: left !important;
}

.action-btns {
  display: none;
}

.justify-center {
  justify-content: center !important;
}
.justify-flex-end {
  justify-content: flex-end !important;
}
.justify-space-between{
  justify-content: space-between !important;
}
.user-card:hover .action-btns {
  display: inline-block !important;
}

.main-loader {
  position: fixed;
  right: 0;
  top: 50%;
  left: 50%;
  z-index: 1000000;
}

#main-loader {
  display: none;
}

.chat-img {
  width: 50px;
  height: 50px;
}
.question-items {
  cursor: "pointer";
  transition: 0.5s;
}
.animate{
  transition: 0.5s  ;
  transition-timing-function: ease-in-out;

}
.animate-hight{
  transition: height 0.5s  ;
  transition-timing-function: ease-in-out;

}
.overflow-y-auto{
  overflow-y: auto !important;
}

.single-line-text{
  margin: 0;
    max-width: 93%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.m-a{
  margin: auto;
}
.question-items:hover{
  cursor: "pointer";
  background-color: #5eb1f51f;

}

.highlight-hover :hover{
  background-color: #fafafa;
}
.table-custom th, .table-custom td {
  padding: 0.25rem 0.9375rem;
  vertical-align: top;
  border-top: none;
}

.table-custom {
  width: 100%;
}

.table-custom th {
  background-color: #d2faff;
}

.table-custom tbody tr:hover {
  background-color: #d6f7fa;
}

.text-one {
  color: red !important;
}

.text-two {
  color: #ff7d0e !important;
}

.text-three {
  color: #d7da00 !important;
}

.text-four {
  color: #1cbccd !important;
}

.text-five {
  color: #00d082 !important;
}

.back-one {
  background-color: red !important;
}

.back-two {
  background-color: #ff7d0e !important;
}

.back-three {
  background-color: #d7da00 !important;
}

.back-four {
  background-color: #1cbccd !important;
}

.back-five {
  background-color: #00d082 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-sm {
  font-size: 10px;
}

/* -------- contract end -------- */

/* width */

::-webkit-scrollbar {
  width: 0px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-action button{
padding: 5px;
margin: 1px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.rbc-calendar {
  background: white;
  width: 90%;
height: 70%;
}
.t-25{
  top:25%;
}
.rbc-day-bg :hover{
  background-color: #3174ad;
  cursor: pointer;
}

.calendar-body{
  max-height: 80vh;
  min-height: 70vh;
  min-width: 60vw;
  max-width: 80vh;
}

.noti-icon{
  color: #9b9b9b !important;
}

.text-shadow{
  text-shadow: -1px -1px 17px rgba(0,0,0,1);
}
.max100Chars{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

}

.animate{
  animation: smooth-appear 1s ease forwards;
}